#daily_tasks_layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    color: var(--dark);
  }
  
  #daily_tasks_table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border-bottom: 1px solid #ddd;
    padding: 10px;
    text-align: center;
    
  }
  
  tr:hover {
    background-color: var(--yellow);
  }
  
  #daily_table_title {
    background-color: var(--light);
    height: 70px;
  }

  .table-container {
    overflow-x: auto;
  }
  