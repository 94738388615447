#time_selection{
    color: var(--dark);
    
}

#time_picker{
    width: 135%;
    height: auto;
    border-color: var(--light);
    
}