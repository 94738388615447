
#under-construction {
    text-align: center;
    margin-top: 200px;
    color: var(--dark);
}

.icon-cone {
    margin-bottom: 20px;
}

.icon-cone svg {
    width: 50px;
    height: 50px;
    fill: var(--dark); 
}
