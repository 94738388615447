#add_task_component{
    background-color: var(--white);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
}

.daily-task-title{
    text-align: center;
    color: var(--green);
    font-family: var(--font-family-primary);
}

.add-button{
    background-color: var(--grey);
    opacity: 0.7;
    border:none;
    border-radius: 4px;
    border-color: var(--green);
    color: var(--light);
    padding: 8px 15px;
    text-align: center;
    font-size: 16px;
    font-family: var(--font-family-secondary);
    width: min-content;
  }

.add-button:hover{
    background-color: var(--green);
    opacity: 1;
}